import React from 'react';

import { AnonAIAssistant } from '~/components/CatalogPage/AnonAIAssistant';
import {
  CatalogHome,
  CatalogHomeProps,
} from '~/components/CatalogPage/CatalogHome';
import { catalogHomePageName } from '~/components/CatalogPage/CatalogHome/const';
import { useCatalogHomePageMeta } from '~/components/CatalogPage/CatalogHome/useCatalogHomePageMeta';
import { PortalPage } from '~/components/PortalPage';

export const CatalogHomeRoute: React.FC<CatalogHomeProps> = (props) => {
  const pageMeta = useCatalogHomePageMeta(props);

  return (
    <PortalPage
      backgroundColor="beige"
      skipToContentId="catalog-heading"
      meta={pageMeta}
      tracking={{
        page_name: catalogHomePageName,
        category: 'home',
      }}
    >
      {/* eslint-disable no-restricted-syntax */}
      {props.anonAIEmbeddedEnabled && (
        <AnonAIAssistant pageName={catalogHomePageName} />
      )}
      <CatalogHome {...props} />
    </PortalPage>
  );
};

export default CatalogHomeRoute;

export { getServerSideProps } from '~/components/CatalogPage/CatalogHome/getServerSideProps';
