import { Box, Text } from '@codecademy/gamut';
import { ContentCardGrid } from '@mono/ui/components';

import { ExternalOrgContent } from '../types';
import { ExternalOrgCard } from './ExternalOrgCard';

export type ExternalOrgContentsProps = {
  contents: ExternalOrgContent[];
};

export const ExternalOrgContents: React.FC<ExternalOrgContentsProps> = ({
  contents,
}) => {
  return (
    <>
      {contents.map((content) => (
        <Box mb={32}>
          <Text as="h2" variant="title-sm" pb={16}>
            {`${content.orgName} content`}
          </Text>
          <ContentCardGrid>
            {content.items.map((item) => (
              <ExternalOrgCard key={item.id} item={item} />
            ))}
          </ContentCardGrid>
        </Box>
      ))}
    </>
  );
};
