import { Anchor, Badge, Box, StrokeButton } from '@codecademy/gamut';
import { LayoutMenu } from '@mono/brand';
import { useTracking } from '@mono/data/use-tracking';
import { useRouter } from 'next/router';
import React from 'react';

import {
  getCatalogPath,
  getSortingQuizPath,
  getWelcomeOnboarding,
} from '~/libs/paths';

import { CategoryData } from '../../types';
import { catalogHomePageName } from '../const';

export type CatalogHomeSideNavProps = {
  topics: CategoryData[];
  sideItems: CategoryData[];
  isTeamsUser: boolean;
  showUnifiedQuiz: boolean;
};

export const CatalogHomeSideNav: React.FC<CatalogHomeSideNavProps> = ({
  topics,
  sideItems,
  isTeamsUser,
  showUnifiedQuiz,
}) => {
  const router = useRouter();
  const { trackUserClick } = useTracking();

  const showBadgeForItem = (slug: string) => slug === 'cybersecurity';

  const quizLink = showUnifiedQuiz
    ? getWelcomeOnboarding()
    : getSortingQuizPath();

  const sections = [
    {
      title: 'All topics',
      slug: 'all-topics',
      items: topics.map((category) => {
        const href = getCatalogPath({
          categoryType: category.categoryType,
          category: category.slug,
        });
        return {
          ...category,
          href,
          onClick: (event: React.MouseEvent) => {
            event.preventDefault();
            trackUserClick({
              page_name: catalogHomePageName,
              context: 'side_menu',
              target: category.slug,
            });
            return router.push(href);
          },
          badge: showBadgeForItem(category.slug) ? (
            <Badge variant="accent" size="sm" alignSelf="center" ml={8}>
              New
            </Badge>
          ) : undefined,
        };
      }),
    },
  ];

  const changeCfBTitle = (title: string) => {
    return title === 'For Business' ? 'Codecademy for Teams' : title;
  };

  return (
    <LayoutMenu
      aria-label="Hub Page Categories"
      closeLabel="Close Catalog Menu"
      sections={sections}
      onSectionToggle={(sectionSlug) =>
        trackUserClick({
          page_name: catalogHomePageName,
          context: 'side_menu',
          target: `${sectionSlug}_collapse`,
        })
      }
      mobileButtonText="Catalog Menu →"
      verticalSpacing={2 as 0}
    >
      <Box mt={16} mb={32}>
        {sideItems.map((item) => {
          return (
            <Anchor
              key={item.slug}
              variant="interface"
              display="block"
              href={getCatalogPath({
                categoryType: item.categoryType,
                category: item.slug,
              })}
              onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                trackUserClick({
                  page_name: catalogHomePageName,
                  context: 'side_menu',
                  target: item.slug,
                });
                return router.push(
                  getCatalogPath({
                    categoryType: item.categoryType,
                    category: item.slug,
                  })
                );
              }}
            >
              {changeCfBTitle(item.title)}
            </Anchor>
          );
        })}
      </Box>
      {!isTeamsUser && (
        <Box pb={0}>
          <StrokeButton
            href={quizLink}
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              trackUserClick({
                page_name: catalogHomePageName,
                context: 'side_menu',
                target: 'sorting_quiz',
              });
              return router.push(quizLink);
            }}
          >
            Where do I begin?
          </StrokeButton>
        </Box>
      )}
    </LayoutMenu>
  );
};
