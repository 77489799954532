import {
  Badge,
  Box,
  FillButton,
  FlexBox,
  Flyout,
  StrokeButton,
  Text,
} from '@codecademy/gamut';
import { FilterIcon } from '@codecademy/gamut-icons';
import pluralize from 'pluralize';
import { useState } from 'react';

import { usePaginatedCatalogContext } from '../context';
import { ClearFiltersButton } from './ClearFiltersButton';
import { Filters } from './Filters';

export const MobileMenu: React.FC = () => {
  const [expanded, setExpanded] = useState(false);

  const {
    filterCount,
    results: { totalResults },
    loading,
  } = usePaginatedCatalogContext();

  const getResultCountText = () =>
    `${totalResults} ${pluralize('results', totalResults)}`;

  const getButtonText = () => {
    return `Show ${getResultCountText()}`;
  };

  const titleSection = (
    <FlexBox
      alignItems="flex-start"
      flexDirection={{ _: 'column', xs: 'row' }}
      rowGap={8}
    >
      <FlexBox alignItems="center">
        <FilterIcon size={24} mr={8} mb={2 as 0} />
        <Text>Filters</Text>
        <Badge ml={12}>{filterCount}</Badge>
      </FlexBox>
      <ClearFiltersButton />
    </FlexBox>
  );

  return (
    <FlexBox
      flexDirection={{ _: 'column', sm: 'row' }}
      justifyContent="space-between"
      alignItems={{ _: 'initial', sm: 'center' }}
    >
      <Box>
        <Flyout
          bg="beige"
          expanded={expanded}
          title={titleSection}
          aria-label="Hub Page Filters"
          closeLabel="Close Filter Menu"
          onClose={() => setExpanded(false)}
        >
          <FlexBox
            bg="beige"
            role="group"
            aria-labelledby="filters-label-mobile"
            flexDirection="column"
            height="100%"
          >
            <FlexBox
              alignItems="center"
              pl={16}
              pb={8}
              borderBottom={1}
              borderColor="navy-300"
            />
            <Box px={16}>
              <Filters />
            </Box>
            <Box flexGrow={1} />
            <FlexBox
              bg="beige"
              justifyContent="center"
              py={24}
              borderTop={1}
              borderColor="navy-300"
              boxShadow="2px 0px 8px rgba(0, 0, 0, 0.25)"
              position="sticky"
              bottom={0}
              width="100%"
            >
              <FillButton
                px={48}
                onClick={() => setExpanded(false)}
                disabled={loading}
              >
                {getButtonText()}
              </FillButton>
            </FlexBox>
          </FlexBox>
        </Flyout>
        <FlexBox alignItems="center">
          <StrokeButton
            variant="secondary"
            p={8}
            onClick={() => setExpanded(true)}
          >
            <FilterIcon size={24} />
            <Text ml={8} fontWeight="bold" as="h3" fontSize="large">
              Filters
            </Text>
            {filterCount > 0 && <Badge ml={8}>{filterCount}</Badge>}
          </StrokeButton>
          <Text pl={12}>{getResultCountText()}</Text>
          <ClearFiltersButton />
        </FlexBox>
      </Box>
    </FlexBox>
  );
};
