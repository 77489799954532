import { TextButton } from '@codecademy/gamut';
import styled from '@emotion/styled';
import { useTracking } from '@mono/data/use-tracking';

import { usePaginatedCatalogContext } from '../../context';
import { clearFiltersTracking } from './const';

export const StyledButton = styled(TextButton)`
  color: ${({ theme }) => theme.colors['navy-600']};
`;
export const ClearFiltersButton: React.FC = () => {
  const { filterCount, resetSelectedFilters } = usePaginatedCatalogContext();
  const { trackUserClick } = useTracking();

  const handleClick = () => {
    trackUserClick(clearFiltersTracking);
    resetSelectedFilters();
  };

  return (
    <StyledButton
      variant="secondary"
      size="small"
      disabled={!filterCount}
      onClick={handleClick}
    >
      Clear filters
    </StyledButton>
  );
};
