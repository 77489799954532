import { FlexBox, SelectDropdown } from '@codecademy/gamut';
import { useTracking } from '@mono/data/use-tracking';
import { useId } from 'react';

import { catalogHomePageName } from '../../const';
import { usePaginatedCatalogContext } from '../context';
import { SortBy } from './types';

export const SortByDropdown: React.FC = () => {
  const { sortBy, setSortBy, triggerFetch, setCurrPageNum } =
    usePaginatedCatalogContext();
  const { trackUserClick } = useTracking();
  const instanceId = useId();

  const sortTrackingNames = {
    [SortBy.POPULAR]: 'sort_popular',
    [SortBy.RECENT]: 'sort_recent',
  };

  return (
    <FlexBox width={180}>
      <SelectDropdown
        id="catalog-results-sort"
        instanceId={instanceId}
        name="catalog-results-sort"
        aria-label="Sort results"
        htmlFor="catalog-results-sort"
        size="small"
        options={Object.values(SortBy)}
        value={sortBy}
        onChange={(event) => {
          if (event) {
            setSortBy(event.value as SortBy);
            setCurrPageNum(1);
            trackUserClick({
              page_name: catalogHomePageName,
              context: 'full_catalog',
              target: sortTrackingNames[event.value as SortBy],
            });
            triggerFetch();
          }
        }}
      />
    </FlexBox>
  );
};
