import {
  Box,
  Column,
  FlexBox,
  LayoutGrid,
  Pagination,
  Text,
} from '@codecademy/gamut';
import { LoadingCurriculumCard } from '@mono/brand';
import { useTracking } from '@mono/data/use-tracking';
import { UserClickData } from '@mono/tracking';
import { ContentCardGrid, VariableCurriculumCard } from '@mono/ui/components';
import pluralize from 'pluralize';
import { useEffect, useRef, useState } from 'react';

import { NoFilterResults } from '../../NoFilterResults';
import { catalogHomePageName } from '../const';
import { usePaginatedCatalogContext } from './context';
import { clearFiltersTracking } from './FilterMenus/ClearFiltersButton/const';
import { DesktopMenu } from './FilterMenus/DesktopMenu';
import { MobileMenu } from './FilterMenus/MobileMenu';
import { SortByDropdown } from './SortByDropdown';

export const PaginatedCatalog: React.FC = () => {
  const cardTracking: Partial<UserClickData> = {
    page_name: catalogHomePageName,
    context: 'full_catalog',
  };

  const { trackUserClick } = useTracking();

  const {
    currPageNum,
    setCurrPageNum,
    results,
    loading,
    triggerFetch,
    resetSelectedFilters,
  } = usePaginatedCatalogContext();

  const topOfResultsRef = useRef<HTMLElement>(null);
  const [scrollToTop, setScrollToTop] = useState(false);

  const [focusOnFirstResult, setFocusOnFirstResult] = useState(false);
  const firstResultRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (loading) {
      setScrollToTop(true);
    }
  }, [loading]);

  useEffect(() => {
    if (
      !loading &&
      scrollToTop &&
      typeof window !== 'undefined' &&
      topOfResultsRef.current
    ) {
      window.scrollTo({
        behavior: 'smooth',
        top: topOfResultsRef.current.offsetTop - 20,
      });
      setScrollToTop(false);

      if (focusOnFirstResult) {
        firstResultRef.current?.focus({ preventScroll: true });
        setFocusOnFirstResult(false);
      }
    }
  }, [loading, scrollToTop, topOfResultsRef, focusOnFirstResult]);

  const handlePageChange = (page: number) => {
    if (page === currPageNum) return;
    trackUserClick({
      page_name: catalogHomePageName,
      context: 'pagination',
      target: `page_${page}`,
    });
    setCurrPageNum(page);
    setFocusOnFirstResult(true);
    triggerFetch();
  };

  const handleNoResultsClearFilter = () => {
    trackUserClick(clearFiltersTracking);
    resetSelectedFilters();
  };

  const getResultsText = () =>
    `${results.totalResults} ${pluralize('results', results.totalResults)}`;

  return (
    <LayoutGrid>
      <Column size={3} display={{ _: 'none', lg: 'block' }}>
        <DesktopMenu />
      </Column>
      <Column size={{ _: 12, lg: 9 }} mb={48} gap={0} display="block">
        <FlexBox
          alignItems="flex-start"
          flexDirection={{ _: 'column', lg: 'row' }}
          mb={16}
        >
          <Text ref={topOfResultsRef} as="h2" variant="title-sm">
            Browse the full catalog
          </Text>
          <Text
            ml={8}
            pt={4}
            display={{ _: 'none', lg: 'block' }}
            aria-live="polite"
          >
            {getResultsText()}
          </Text>
          <Box display={{ _: 'block', lg: 'none' }} mt={16} mb={24}>
            <MobileMenu />
          </Box>
          <Box flex={1} display={{ _: 'none', lg: 'block' }} />
          <SortByDropdown />
        </FlexBox>
        {results.pageItems.length ? (
          <ContentCardGrid>
            {results.pageItems.map((c, idx) => (
              <FlexBox minHeight={302} width="auto" key={c.id}>
                {!loading ? (
                  <VariableCurriculumCard
                    trackingData={cardTracking}
                    content={c}
                    ref={idx === 0 ? firstResultRef : null}
                  />
                ) : (
                  <LoadingCurriculumCard />
                )}
              </FlexBox>
            ))}
          </ContentCardGrid>
        ) : (
          <NoFilterResults clearFilterCallback={handleNoResultsClearFilter} />
        )}
        {results.totalPages > 1 && (
          <Box mt={48}>
            <Pagination
              pageNumber={currPageNum}
              totalPages={results.totalPages}
              onChange={handlePageChange}
              key={`${currPageNum}/${results.totalPages}`} // forces react to rerender and pick up the correct aria live label for page ranges
            />
          </Box>
        )}
      </Column>
    </LayoutGrid>
  );
};
