import { IconButton } from '@codecademy/gamut';
import { css, states } from '@codecademy/gamut-styles';
import { StyleProps } from '@codecademy/variance';
import styled from '@emotion/styled';

const arrowButtonStates = states({
  disabled: { borderColor: 'background-disabled' },
});
export const ArrowButton = styled(IconButton)<
  StyleProps<typeof arrowButtonStates>
>(css({ borderColor: 'currentColor' }), arrowButtonStates);
