import {
  CatalogCategoryDataFragment,
  PaginatedCatalogQuery,
} from '@mono/data/codegen/client/graphql-gateway/graphql';
import { rawToFinalCardData } from '@mono/data/curriculum-cards';

import { rawToCategoryData } from '../helpers';
import { ContentCounts } from './HubSpotlight';
import { CategoryDataCollection, PaginatedCatalogResults } from './types';

type ContentCountItem = {
  type: string;
  goal?: string | null;
};

export const normalizeContentCounts = (content: ContentCountItem[]) => {
  return content.reduce<ContentCounts>(
    (acc, item) => {
      const newKey =
        item.type === 'Path'
          ? item.goal === 'skill'
            ? 'skillPathCount'
            : 'careerPathCount'
          : item.type === 'ExternalPath'
          ? 'skillPathCount'
          : 'courseCount';
      if (!acc[newKey]) {
        acc[newKey] = 1;
      } else {
        acc[newKey] += 1;
      }
      return acc;
    },
    {
      courseCount: 0,
      skillPathCount: 0,
      careerPathCount: 0,
    }
  );
};

export const createCategoryDataCollection = (
  rawCategories: CatalogCategoryDataFragment[],
  rawPopular: CatalogCategoryDataFragment[]
): CategoryDataCollection => {
  const categoryItems = rawToCategoryData(rawCategories);

  const standard = categoryItems.filter(
    (item) => !['for-business', 'beta'].includes(item.slug)
  );

  const special = categoryItems.filter((item) =>
    ['for-business', 'beta'].includes(item.slug)
  );

  return {
    standard,
    special,
    popular: rawToCategoryData(rawPopular),
  };
};

export const getPaginatedCatalogResults = (
  raw: PaginatedCatalogQuery
): PaginatedCatalogResults => {
  const data = raw.paginatedContainers;
  return {
    pageItems: rawToFinalCardData(data?.collection || []),
    totalResults: data?.paginationMetadata.totalResults || 0,
    totalPages: data?.paginationMetadata.totalPages || 0,
  };
};
