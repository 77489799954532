import { Course } from 'schema-dts';

import { getBaseUrl, getCatalogBasePath } from '~/libs/paths';
import { curriculumCardsToSEOCourseItems } from '~/libs/schema/catalogSchemaHelper';
import constructPageSchemaForItemList from '~/libs/schema/pageSchemaHelper';

import { CatalogHomeProps } from '.';

export const pageMetaTitle = 'Catalog Home';

export const pageMetaDescription =
  'If you’re not sure where to begin or what to learn next, this is a great place to start. Check out our top coding courses, Skill Paths, and Career Paths.';

export const pageUrl = getBaseUrl() + getCatalogBasePath();

export const useCatalogHomePageMeta = (props: CatalogHomeProps) => {
  const {
    featuredContainers,
    initialCatalogResults: { pageItems },
  } = props;

  const seen: Record<string, boolean> = {};

  const combined = [...featuredContainers, ...pageItems].filter((card) => {
    if (seen[card.slug]) {
      return false;
    }
    seen[card.slug] = true;
    return true;
  });

  const itemList: Course[] = curriculumCardsToSEOCourseItems(combined);

  const pageSchema = constructPageSchemaForItemList({
    name: pageMetaTitle,
    url: pageUrl,
    itemListElement: itemList,
  });

  return {
    title: pageMetaTitle,
    description: pageMetaDescription,
    pageSchema,
  };
};
